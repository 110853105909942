import { mtmMainOptions } from 'constants/mtm-content-options'
import { randomizeThemeNumber } from 'utils/randomizeThemeNumber'
const { option1, option2, option3 } = mtmMainOptions

export const getThemeNumberBasedOnMtmContentValue = (
  mtmContentValue: string | null
) => {
  if (!mtmContentValue) return randomizeThemeNumber(1, 3)

  const isMtmOption1 = mtmContentValue.includes(option1)
  const isMtmOption2 = mtmContentValue.includes(option2)
  const isMtmOption3 = mtmContentValue.includes(option3)

  if (isMtmOption1) return 1
  if (isMtmOption2) return 2
  if (isMtmOption3) return 3

  return randomizeThemeNumber(1, 3)
}
