import React, { createContext, useState, useEffect, useMemo } from 'react'

import type { Flat } from 'types/flat'

type Props = {
  children: React.ReactNode
}

type FavouritesContextType = {
  favourites: Flat[]
  clearFavourites: () => void
  removeFromFavourites: (flat: Flat) => void
  checkIsFavourite: (flat: Flat) => boolean
  handleFavOption: (flat: Flat, addOnly?: boolean) => void
  removeFavourites: () => void
}

const DEFAULT_STATE = {
  favourites: [],
  clearFavourites: () => {},
  removeFromFavourites: () => {},
  checkIsFavourite: () => false,
  handleFavOption: () => {},
  removeFavourites: () => {},
}

export const FavouritesContext =
  createContext<FavouritesContextType>(DEFAULT_STATE)

const FavouritesProvider: React.FC<Props> = ({ children }) => {
  const [favourites, setFavourites] = useState<Flat[]>([])

  const updateFavourites = (newFavs: Flat[]) => {
    localStorage.setItem('favourites-na-bielany', JSON.stringify(newFavs))
    setFavourites(newFavs)
  }

  const removeFavourites = () => {
    localStorage.setItem('favourites-na-bielany', JSON.stringify([]))
    setFavourites([])
  }

  const addToFavourites = (flat: Flat) => {
    updateFavourites([...favourites, flat])
  }

  const removeFromFavourites = (flat: Flat) => {
    const elementToRemove = favourites.findIndex(
      ({ name, investment }) =>
        flat.name === name && investment === flat.investment
    )
    updateFavourites(favourites.filter((_, index) => index !== elementToRemove))
  }

  const checkIsFavourite = (flat: Flat) => {
    if (favourites.length !== 0) {
      return favourites.some(
        ({ name, investment }) =>
          flat.name === name && investment === flat.investment
      )
    }
    return false
  }

  const handleFavOption = (flat: Flat, addOnly = false) => {
    if (checkIsFavourite(flat)) {
      if (!addOnly) removeFromFavourites(flat)
      return
    }

    //@ts-ignore
    if (window.dataLayer) {
      //@ts-ignore
      window.dataLayer.push({
        event: 'add_to_wishlist',
        //@ts-ignore
        mieszkanie: flat.number,
      })
    }

    addToFavourites(flat)
  }

  const clearFavourites = () => {
    updateFavourites([])
  }

  useEffect(() => {
    const localFavs =
      typeof window !== `undefined`
        ? JSON.parse(localStorage.getItem('favourites-na-bielany') ?? '[]')
        : []
    setFavourites(localFavs)
  }, [])

  const providerValues = useMemo(
    () => ({
      favourites,
      clearFavourites,
      removeFromFavourites,
      checkIsFavourite,
      handleFavOption,
      removeFavourites,
    }),
    [
      favourites,
      clearFavourites,
      removeFromFavourites,
      checkIsFavourite,
      handleFavOption,
      removeFavourites,
    ]
  )

  return (
    <FavouritesContext.Provider value={providerValues}>
      {children}
    </FavouritesContext.Provider>
  )
}

export default FavouritesProvider
