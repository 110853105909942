export const getSavedMtmContentValue = () => {
  if (typeof localStorage !== `undefined`) {
    return localStorage.getItem('mtm_content')
  }
}

export const saveMtmContentValue = (mtmContentValue: string) => {
  if (typeof localStorage !== `undefined`) {
    localStorage.setItem('mtm_content', mtmContentValue)
  }
}
