export const mtmMainOptions = {
  option1: 'agnieszka',
  option2: 'marcin',
  option3: 'anna',
}

export const mtmHeroOptions = {
  option1: 'agnieszka-a',
  option2: 'agnieszka-b',
  option3: 'agnieszka-c',
  option4: 'marcin-a',
  option5: 'marcin-b',
  option6: 'marcin-c',
  option7: 'anna-a',
  option8: 'anna-b',
  option9: 'anna-c',
}
