import React from 'react'

import { LayoutProvider } from 'contexts/LayoutContext'
import FavouritesProvider from 'contexts/FavouritesContext'

export const wrapPageElement = ({ element }) => (
  <LayoutProvider>
    <FavouritesProvider>{element}</FavouritesProvider>
  </LayoutProvider>
)
